<template>
  <section class="withdraw-usdc" :class="{ '--continue-transfer': confirm_transfer }">
    <!-- Initial State -->
    <template v-if="!confirm_transfer">
      <div class="h-full flex flex-col justify-between">
        <div>
          <h1 class="headline-small mb-s40" v-text="$t('transfer_usdc.title')" />

          <div class="flex justify-between pb-s16 mb-s16 border-b-1 border-gray-400">
            <span class="subheadline-x-small" v-text="$t('transfer_usdc.amount')"/>
            <span class="subheadline-small text-text-positive" v-text="formattedAmount"/>
          </div>

          <div v-if="getChainNames({ isDeposit: false }).length">
            <div class="body-text-x-large mb-s8" v-text="$t('transfer_usdc.select_chain')" />
            <CustomSelect
                :items="getChainNames({ isDeposit: false })"
                :tabindex="0"
                :selectedItem="getSelectedChainName({ isDeposit: false })"
                :isOpen="false"
                :visibleItems="4"
                :placeholder="$tc('transfer_usdc.choose_option')"
                @onSelectItem="selectChain"
            />

            <div v-if="getSelectedChainName({ isDeposit: false })" class="mt-s24">
              <p class="body-text-x-large mb-s8" v-text="$t('transfer_usdc.your_usdc_wallet')" />
              <CustomInput 
              testId="wallet-address"
              :value="withdraw_wallet_address" @input="setUsdcWithdrawAddress" />
            </div>

            <div v-if="displayMemoInput">
              <p class="body-text-x-large mb-s8" v-text="$t(`transfer_usdc.address_tag${memoRequirement === CircleChainAddressTagSpecification.OPTIONAL ? '_optional' : ''}`)"/>
              <CustomInput 
              testId="wallet-address-tag"
              :value="withdraw_wallet_address_tag" @input="setUsdcWithdrawAddressTag" />
            </div>
          </div>
        </div>

        <div class="body-text-large">{{ $t('transfer_usdc.withdraw_warning') }}</div>
      </div>

      <div class="flex flex-col">
        <ButtonV2
          class="my-s16"
          wide
          size="medium"
          version="primary"
          :label="$tc('transfer_usdc.send_usdc')"
          testId="btn-sendUsdc"
          :inactive="!isValid"
          @onClick="continueTransfer"
        />
        <ButtonV2 
          wide
          size="medium"
          version="secondary"
          testId="btn-usdcCancel"
          :label="$tc('transfer_usdc.cancel')"
          @onClick="cancel"
        />
      </div>
    </template>

    <!-- Confirm Transfer -->
    <template v-else>
      <div>
        <h1 class="headline-small mb-s40" v-text="$t('transfer_usdc.title')" />
        <p class="body-text-large mb-s40" v-text="$t('transfer_usdc.withdraw_confirmation_warning')" />
      </div>

      <div class="flex flex-col">
        <ButtonV2
          class="mb-s16"
          wide
          size="medium"
          version="primary"
          :label="$tc('transfer_usdc.agree')"
          testId="btn-agree"
          :loading="isLoading"
          :inactive="!isValid"
          @onClick="confirmTransfer"
        />
        <ButtonV2 
          wide
          size="medium"
          version="secondary"
          :label="$tc('transfer_usdc.go_back')"
          testId="btn-goBack"
          :loading="isLoading"
          @onClick="goBack"
        />
      </div>
    </template>
  </section>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { ButtonV2, CustomSelect, CustomInput } from '@/components/misc';
import CircleChainAddressTagSpecification from '@/enums/CircleChainAddressTagSpecification';

export default {
  name: 'WithdrawUsdc',
  
  props: {
    amount: {
      type: [String, Number],
      required: true
    }
  },

  data() {
    return {
      confirm_transfer: false,
      CircleChainAddressTagSpecification,
      isLoading: false,
    };
  },
  
  components: { 
    ButtonV2, 
    CustomSelect,
    CustomInput,
  },
  
  computed: {
    ...mapGetters('user', ['getUserEmail']),

    ...mapGetters('wallet', [
      'getChainSpecifications',
      'getChainNames',
      'getSelectedChainName',
      'getSelectedChainSpecification'
    ]),

    ...mapState('wallet', [
      'circle_available_deposit_chains',
      'selected_withdraw_chain',
      'withdraw_wallet_address',
      'withdraw_wallet_address_tag',
      'currentWithdrawAmount',
    ]),

    displayMemoInput() {
      return this.memoRequirement === CircleChainAddressTagSpecification.OPTIONAL || this.memoRequirement === CircleChainAddressTagSpecification.REQUIRED;
    },

    formattedAmount() {
      return this.numberFormat(this.amount, 2, false, true);
    },

    isValid() {
      if (this.memoRequirement === CircleChainAddressTagSpecification.REQUIRED && !this.withdraw_wallet_address_tag) {
        return false;
      }

      return !!this.selected_withdraw_chain
        && this.withdraw_wallet_address 
        && this.withdraw_wallet_address.length > 0;
    },

    memoRequirement() {
      return this.lodashGet(this.getSelectedChainSpecification({ isDeposit: false }), 'tag_spec', null);
    }
  },
  
  methods: {
    ...mapActions('wallet', [
      'getUsdcChains',
      'setSelectedWithdrawChain',
      'handleUsdcWithdraw',
      'resetUsdcWithdraw',
      'setUsdcWithdrawAddress',
      'setUsdcWithdrawAddressTag',
      'setWithdrawAmount',
    ]),

    selectChain(chainName) {
      const chainSpecification = this.getChainSpecifications({ isDeposit: false }).find(chain => chain.name === chainName);
      this.setSelectedWithdrawChain(chainSpecification.chain);
      this.setUsdcWithdrawAddress(null);
      this.setUsdcWithdrawAddressTag(null);
    },

    continueTransfer() {
      this.confirm_transfer = true;
    },

    async confirmTransfer() {
      try {
        this.isLoading = true;

        await this.handleUsdcWithdraw({
          amount: this.currentWithdrawAmount,
          chain: this.selected_withdraw_chain,
          address: this.withdraw_wallet_address,
          addressTag: this.withdraw_wallet_address_tag
        });
        await this.$store.dispatch('events/track', {
          event: 'RECEIVE_USDC_SELECTED',
        });
      } catch (err) {
        this.showError(err);
      } finally {
        this.isLoading = false;
      }
    },

    cancel() {
      this.resetUsdcWithdraw();
    },

    goBack() {
      this.confirm_transfer = false;
    },
  },

  mounted() {
    this.setWithdrawAmount(this.amount);
    this.getUsdcChains({ isDeposit: false });
  },
  
};
</script>

<style scoped>
.withdraw-usdc {
  @apply 
    flex 
    flex-col 
    justify-between
    flex-1;
  max-width: 440px;
  min-height: 600px;
}
.--continue-transfer {
  min-height: 0;
}
</style>
